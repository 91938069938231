











import Vue from "vue";
import marked from "marked";
import axios from "axios";
let rendererMD = new marked.Renderer();
marked.setOptions({
  renderer: rendererMD,
  gfm: true,
  breaks: false,
  pedantic: false,
  sanitize: false,
  smartLists: true,
  smartypants: false,
});
rendererMD.heading = function (text, level) {
  return `<h${level} style="margin-bottom:20px;margin-top:20px">${text}</h${level}>`;
};
rendererMD.link = function (href, title, text) {
  return `<a style="color:#4183c4;text-decoration: none;" href=${href}>${text}</a>`;
};
export default Vue.extend({
  data: () => ({
    name: [] as any,
    date: [] as any,
    content: ``
  }),
  created() {
    console.log("subpage", this.$route.name);
  },
  computed: {
    compiledMarkdown: function () {
      return marked(this.content);
    },
  },
  mounted: function () {
    axios
      .post(Vue.prototype.serverAddr + "/api/get-item-by-id", {
        collection: "article",
        _id: this.$route.params.id,
      })
      .then((response) => {
        let tmp = (response.data as any)[0];
        this.name = tmp.name
        console.log("adfaas")
        console.log(tmp)
        this.date = (new Date(tmp.date)).toISOString().split('T')[0]
        this.content = tmp.content.replace(/localhost/g, Vue.prototype.serverAddr);
      });
  },
});
